@import "/src/assets/styles/screens.module";
@import "/src/assets/styles/colors.module";

#footer {
  width: 100%;
  background-color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $desktop) {
    height: 232px;
  }

  .footer__wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: $desktop) {
      width: 90%;
    }

    .footer__topDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin: 0 auto;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 42.5px;
      border-bottom: 1px solid $grey3;
      margin-bottom: 30px;

      @media (max-width: $desktop) {
        flex-direction: column;
        align-items: initial;
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .footer__logoWrapper {
        flex: 0.33;
        display: flex;
        justify-content: flex-end;

        @media (max-width: $desktop) {
          margin-bottom: 20px;
        }

        .footer__logo-button {
          background-color: transparent;
          border-width: 0;
          cursor: pointer;
          .logoImage{
            height: 52px !important;
            @media (max-width: $desktop) {
              height: 28px !important;
            }
          }
        }
      }

      .footer__topDetails-links {
        flex: 0.33;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: center;

        @media (max-width: 1200px) {
          flex-direction: column;
        }

        @media (max-width: $desktop) {
          align-items: flex-end;
        }

        .footer__link {
          text-decoration: none;
          color: black;
          font-family: "secular one";
          font-size: 18px;
          margin: 0 12.5px;

          &:hover {
            color: $torquise;
          }

          @media (max-width: $desktop) {
            font-size: 16px;
            margin: 0;
            margin-bottom: 32px;
          }
        }
      }

      .footer__topDetails-customerService {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        flex: 0.33;
        justify-content: flex-end;

        @media (max-width: $desktop) {
          justify-content: flex-start;
        }

        .footer__topDetails-phoneLogo {
          margin-left: 15px;
          width: 60px;
          object-fit: contain;
        }

        .footer__topDetails-customerServiceDetails {
          & > div:first-child {
            font-size: 16px;
            font-family: "open sans";
            margin-bottom: 1px;
            text-align: right;
            margin-top: 6px;
          }

          & > div:last-child {
            font-size: 32px;
            font-family: "secular one";
            text-align: right;

            @media (max-width: $desktop) {
              font-size: 24px;
            }
          }
        }
      }
    }

    .footer__bottomDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin: 0 auto;
      width: 100%;
      padding-bottom: 29px;

      & > div:not(:nth-child(2)) {
        flex: 1;
      }

      .footer__bottomDetails-links {
        text-align: right;
        min-width: fit-content;

        .footer__link {
          color: $grey1;
          font-size: 14px;
          font-family: "open sans";
        }

        & > .footer__link:nth-child(2) {
          margin: 0 4%;
        }
      }

      .footer__bottomDetails-middle {
        direction: rtl;
        text-align: center;

        & > div {
          font-size: 14px;
          font-weight: bold;
          font-family: "open sans";
          margin-bottom: 5px;
        }

        & > a {
          color: $torquise;
          font-family: "open sans";
          font-weight: normal;
          font-size: 14px;
        }
      }

      .footer__bottomDetails-copyright {
        display: flex;
        justify-content: flex-end;
        white-space: pre-line;
        text-align: center;
        direction: rtl;
        color: $grey1;
        font-family: "open sans";
        font-weight: normal;
        font-size: 14px;
      }
    }

    .footer__bottomDetails-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      padding-bottom: 29px;

      .footer__bottomDetails-links {
        text-align: center;
        width: 100%;

        .footer__link {
          color: $grey1;
          font-size: 14px;
          font-family: "open sans";
        }

        & > .footer__link:nth-child(2) {
          margin: 0 4%;
        }
      }

      .footer__bottomDetails-top {
        direction: rtl;
        text-align: center;
        margin-bottom: 24px;

        @media (min-width: $desktop) {
          margin-left: 10px;
        }

        & > div {
          font-size: 14px;
          font-weight: bold;
          font-family: "open sans";
          margin-bottom: 5px;
        }

        & > a {
          color: $torquise;
          font-family: "open sans";
          font-weight: normal;
          font-size: 14px;
        }
      }

      .footer__bottomDetails-copyright {
        white-space: pre-line;
        text-align: center;
        direction: rtl;
        color: $grey1;
        font-family: "open sans";
        font-weight: normal;
        font-size: 14px;
        margin-top: 16px;
      }
    }
  }
}
